import { useSubscriptionEnabled } from 'hooks/useSubscriptionEnabled';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

export const useGuestMyPageEnabled = () => {
  const subscriptionEnabled = useSubscriptionEnabled('feature-guest-my-page');

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const settingEnabled =
    apiSettings?.guest_my_page_settings?.allow_viewing_reservation_details ?? false;

  return subscriptionEnabled && settingEnabled;
};
